import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RegistrationPage from '../RegistrationPage';
import Products from "../Products";

const FormLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Добавляем состояние для ошибки

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.token) {
                localStorage.setItem('token', data.token); // Сохраняем токен в локальное хранилище
                window.location.href = '/'; // Переход на главную страницу после успешного входа
            } else {
                setErrorMessage(data.message || 'Ошибка при входе'); // Обновляем сообщение об ошибке
            }
        } catch (error) {
            setErrorMessage(error.message || 'Произошла ошибка при попытке войти'); // Обновляем сообщение об ошибке
        }
    };
    return (
        <>
            <section data-bs-version="5.1" className="form5 cid-uB6UCgJAW0" id="form02-t">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 content-head">
                            <div className="mbr-section-head mb-6">
                                <h3 className="mbr-section-title mbr-fonts-style text-primary mb-0 display-2"><strong>Please Login</strong></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                            <form onSubmit={handleSubmit} className="mbr-form form-with-styler" data-form-title="Form Name">
                                <div className="row">
                                    <div hidden className="alert alert-success col-12"></div>
                                    <div hidden className="alert alert-danger col-12">
                                        Oops...! some problem!
                                    </div>
                                </div>
                                <div className="dragArea row">

                                    <div className="col-md col-sm-12 form-group mb-3" data-for="username">
                                        <input
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            data-form-field="username"
                                            className="form-control"
                                            value={username}
                                            id="username-form02-t"
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-12 form-group mb-3" data-for="password">
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            data-form-field="password"
                                            className="form-control"
                                            value={password}
                                            id="password-form02-t"
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="nav-item">
                                        <a className="nav-link link mbr-fonts-style align-right text-primary display-7"
                                           href={RegistrationPage.link}>Зарегистрироваться</a>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                                        <button type="submit" className="btn btn-info display-1">Войти</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FormLogin;
