import {Component} from "react";
import FirstPage from "./FirstPage";
import {Router, Route, Routes} from "react-router-dom";
import { Link } from 'react-router-dom';
import Products from "./Products";
import DemoPage from "./DemoPage";
import AboutPage from "./AboutPage";
import LoginPage from "./LoginPage";
import RegistrationPage from "./RegistrationPage";


export default class Main extends Component {

    render() {
        return (<>
                <Router location={window.location} navigator={window.history}>
                    <Routes>
                        <Route exact index={true} path={FirstPage.link} element={<FirstPage/>}/>
                        <Route path={AboutPage.link} element={<AboutPage/>}/>
                    </Routes>
                </Router>
            </>);
    }
}