import React, { useState } from 'react';

const FormRegistration = () => {
    const [username, setUsername] = useState(''); // Используем "username" вместо "name"
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Добавляем состояние для ошибки
    const [passwordError, setPasswordError] = useState(''); // Состояние для ошибки пароля

    // Регулярное выражение для проверки допустимости пароля
    const passwordRegex = /^[A-Za-zА-Яа-я0-9_]+$/;

    const handleChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'password':
                // Проверяем пароль на допустимость
                if (!passwordRegex.test(value)) {
                    setPasswordError('Пароль должен содержать только буквы, цифры и знак подчеркивания.'); // Сообщение об ошибке пароля
                    return;
                }
                setPasswordError(''); // Ошибка устранена
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Пароли не совпадают");
            return;
        }

        try {
            // Отправка данных на сервер
            const response = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Указываем, что тело запроса — JSON
                },
                body: JSON.stringify({ // Формируем объект для отправки
                    username, // Отправляем значение username
                    email,    // Отправляем значение email
                    password  // Отправляем значение password
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json(); // Получаем ответ от сервера

            if (data.error) {
                setErrorMessage(data.error.message || 'Ошибка при регистрации');
            } else {
                // Успешная регистрация
                // Можно перенаправить пользователя на страницу подтверждения или входа
                window.location.href = '/login'; // Переходим на страницу входа
            }
        } catch (error) {
            setErrorMessage(error.message || 'Произошла ошибка при попытке зарегистрироваться');
        }
    };

    return (
        <section className="form5 cid-uB6UCgJAW0" id="form02-w">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 content-head">
                        <div className="mbr-section-head mb-5">
                            <h3 className="mbr-section-title mbr-fonts-style text-primary mb-0 display-2">
                                <strong>Регистрация</strong>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                        <form onSubmit={handleSubmit} className="mbr-form form-with-styler" data-form-title="Form Name">
                            <input type="hidden" name="email" data-form-email="true" value="S9kn0o1wcSrFNQ9DNpLe7P8PpPDHcRmD9w/fiIv6MsKLHxwli1StymERNbifELZehDkaASZZqZr+gLUlcaImeNyb0SmxQ0lF/jjoMRo6aMdQkw6ui1IhmoghN2VEI7Hj" />
                            <div className="row">
                                <div hidden data-form-alert="" className="alert alert-success col-12"></div>
                                <div hidden data-form-alert-danger="" className="alert alert-danger col-12">
                                    {errorMessage && errorMessage} {/* Отображаем общие ошибки */}
                                </div>
                            </div>
                            <div className="dragArea row">
                                <div className="col-md col-sm-12 form-group mb-3" data-for="name">
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Имя"
                                        data-form-field="username"
                                        className="form-control"
                                        value={username}
                                        id="username-form02-w"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md col-sm-12 form-group mb-3" data-for="email">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Электронная почта"
                                        data-form-field="email"
                                        className="form-control"
                                        value={email}
                                        id="email-form02-w"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 form-group mb-3" data-for="password">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Пароль"
                                        data-form-field="password"
                                        className="form-control"
                                        value={password}
                                        id="password-form02-w"
                                        onChange={handleChange}
                                    />
                                    {passwordError && ( // Показываем сообщение об ошибке, если оно существует
                                        <div className="invalid-feedback d-block">{passwordError}</div>
                                    )}
                                </div>
                                <div className="col-12 form-group mb-3" data-for="confirmPassword">
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Подтверждение пароля"
                                        data-form-field="password"
                                        className="form-control"
                                        value={confirmPassword}
                                        id="confirm-password-form02-w"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                                    <button type="submit" className="btn btn-info display-4">Зарегистрироваться</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormRegistration;