import AbstractLinkedPage from "./AbstractLinkedPage";
import FormRegistration from "./components/FormRegisteration";

export default class RegistrationPage extends AbstractLinkedPage {

    static link = "/regpage";

    render() {
        return (<>
            <></>
            <FormRegistration/>
        </>);
    }

}