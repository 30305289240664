import AbstractLinkedPage from "./AbstractLinkedPage";
import FormLogin from "./components/FormLogin";

export default class LoginPage extends AbstractLinkedPage {

    static link = "/loginpage";

    render() {
        return (<>
            <></>
            <FormLogin/>

            </>);
    }

}